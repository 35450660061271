import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import services from "../content/services"


const LeistungenPage = () => (
  <Layout>
    <Seo title="Leistungen" />
    <section className="section">
      <div className="container is-max-desktop">
        <div className="content">
          <h1 className="title is-1 mb-6">Leistungen</h1>
          <div className="columns">
            <div className="column is-8">
              <div className="content is-medium">
                <p style={{ "hyphens": "auto" }}>
                  Für alle gesetzlichen Krankenkassen und Privatkassen.
                  <br /><br />
                  Die Preise von Selbstzahlern und Privatpatienten richten sich nach den 1,4 bis 1,8 fachen Steigerungssatz der aktuellen GebüH (Gebührenübersicht für Therapeuten).
                  Für Privatpatienten gilt dieser Preis, auch wenn dieser nicht in voller Höhe von Ihrer Krankenversicherung bezahlt wird.  Eine Übernahme der Kosten ist abhängig von Ihrem individuellen Versicherungsvertrag. In Ihrem eigenen Interessen sollte Sie dies vor der Unterzeichnung der Honorarvereinbarung abklären.
                </p>
              </div>
            </div>
            <div className="column has-text-right  is-4">
              <StaticImage
                src="../images/praxis/21-10-27_10-02-42_DSCF2876.jpg"
                alt="Physiotherapie Equipment (Matten, Hanteln, Balance-Kissen, Hocker)"
                style={{ "borderRadius": "5px", "maxWidth": "300px" }}
                aspectRatio={3 / 4}
                transformOptions={{
                  cropFocus: "entropy"
                }}
              />
            </div>
          </div>
          <h2 id="preisliste" className="mt-6">Preisliste</h2>
          <div className="content mt-6 mb-6">
            {
              services.map((s, index) => (
                <div className={`columns ${index % 2 === 0 ? 'has-background-grey-lighter border-radius' : ''}`} key={s.title}>
                  <div className="column">
                    <b>{s.title}</b><br />
                    {s.description !== null && <span className="has-text-grey">{s.description}</span>}
                  </div>
                  <div className="column has-text-right has-text-left-mobile">
                    {s.options.map(o => (
                      o.duration
                        ? <p key={s.title + o.price}>{o.duration} min &nbsp;&nbsp;| &nbsp;&nbsp; {o.price} &euro;</p>
                        : <p key={s.title + o.price}>{o.price} &euro;</p>
                    ))}
                  </div>
                </div>
              ))
            }
            <p>
              <br/>
              <br/>
              Vergütungsbeispiel anhand einer Krankengymnastik
              <ul>
                <li>GKV = 27,80 &euro;</li>
                <li>PKV = 50 &euro;</li>
              </ul>
            </p>
            <h2 id="termin-absage" className="mt-6">Terminabsage</h2>
            <p>Falls Sie ihren Termin nicht wahrnehmen können, bitte ich um eine rechtzeitige Absage (24 Stunden vor dem Termin) per Telefon oder E-Mail. Dies gibt mir die Option, den Termin anderweitig zu vergeben.
              Beachten sie bitte: bei nicht oder nicht rechtzeitger Absage stelle ich Ihnen den ausgefallenen Termin privat in Rechnung.</p>
          </div>
        </div>
      </div>
    </section>
  </Layout >
)

export default LeistungenPage
