const services = [
    {
        title: 'Manuelle Therapie',
        description: null,
        options: [
            {
                duration: '25',
                price: '45'
            }
        ]
    },
    {
        title: 'Krankengymnastik (Allgemein)',
        description: 'Krankengymnastik auf neurophysiologischer Grundlage',
        options: [
            {
                duration: '25',
                price: '40'
            }
        ]
    },
    {
        title: 'Krankengymnastik ZNS PNF',
        description: null,
        options: [
            {
                duration: '30',
                price: '60'
            }
        ]
    },
    {
        title: 'Klassische Massagetherapie',
        description: null,
        options: [
            {
                duration: '20',
                price: '35'
            }
        ]
    },
    {
        title: 'Segment-, Periost-, Colonmassage',
        description: null,
        options: [
            {
                duration: '20',
                price: '35'
            }
        ]
    },
    {
        title: 'Bindegewebsmassage',
        description: null,
        options: [
            {
                duration: '20',
                price: '35'
            }
        ]
    },
    {
        title: 'Manuelle Lymphdrainage',
        description: null,
        options: [
            {
                duration: '30',
                price: '45'
            },
            {
                duration: '45',
                price: '70'
            },
            {
                duration: '60',
                price: '90'
            }
        ]
    },
    {
        title: 'Fußreflexzonentherapie',
        description: null,
        options: [
            {
                duration: '25',
                price: '35'
            },
            {
                duration: '50',
                price: '70'
            }
        ]
    },
    // {
    //     title: 'Heiße Rolle',
    //     description: null,
    //     options: [
    //         {
    //             duration: '10',
    //             price: '15'
    //         }
    //     ]
    // },
    {
        title: 'Ultraschall',
        description: null,
        options: [
            {
                duration: '10',
                price: '20'
            }
        ]
    },
    {
        title: 'Fangopackung',
        description: null,
        options: [
            {
                duration: '20-30',
                price: '20'
            }
        ]
    },
    // {
    //     title: 'Kryotherapie',
    //     description: null,
    //     options: [
    //         {
    //             duration: '10',
    //             price: '13'
    //         }
    //     ]
    // },
    {
        title: 'Kinesio Tape',
        description: null,
        options: [
            {
                duration: null,
                price: '15'
            }
        ]
    },
    {
        title: 'Hausbesuch inklusive Wegegeld',
        description: null,
        options: [
            {
                duration: null,
                price: '30'
            }
        ]
    },
    {
        title: 'Therapieverlängerung (für GKV Rezepte)',
        description: null,
        options: [
            {
                duration: '10',
                price: '15'
            }
        ]
    }
]

export default services